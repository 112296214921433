import MoveRandomized from '@gamepark/a-fistful-of-meeples/moves/MoveRandomized'
import MoveType from '@gamepark/a-fistful-of-meeples/moves/MoveType'
import {Action, Rules, Undo} from '@gamepark/rules-api'
import {getAutomaticMove, getCanUndo, playMove} from '../../rules/src/AFistfulOfMeeples'
import GameState, {PendingEffectType} from '../../rules/src/GameState'
import Move from '../../rules/src/moves/Move'
import PlayerColor from '../../rules/src/PlayerColor'

/**
 * Your Board Game rules must extend either "SequentialGame" or "SimultaneousGame".
 * When there is at least on situation during the game where multiple players can act at the same time, it is a "SimultaneousGame"
 * If the game contains information that players does not know (dices, hidden cards...), it must implement "IncompleteInformation".
 * If the game contains information that some players know, but the other players does not, it must implement "SecretInformation" instead.
 * Later on, you can also implement "Competitive", "Undo", "TimeLimit" and "Eliminations" to add further features to the game.
 */
export default class AFistfulOfMeeplesView extends Rules<GameState, MoveRandomized, PlayerColor> implements Undo<GameState, MoveRandomized, PlayerColor> {

  getActivePlayer(): PlayerColor | undefined {
    if (this.state.pendingEffects.length > 0) {
      let effect = this.state.pendingEffects[0]
      if (effect.type === PendingEffectType.BuildOrUpgradeMarquee && this.state.marquees[effect.location].owner !== undefined) {
        return this.state.marquees[effect.location].owner
      }
      if (effect.type === PendingEffectType.ChooseToRerollShowdownDice)
        return effect.player
    }

    return this.state.activePlayer // You must return undefined only when game is over, otherwise the game will be blocked.
  }

  play(move: MoveRandomized): MoveRandomized[] {
    playMove(this.state, move)
    return []
  }

  canUndo(action: Action<Move, PlayerColor>, consecutiveActions: Action<Move, PlayerColor>[]): boolean {
    return getCanUndo(action, consecutiveActions)
  }

  getAutomaticMoves(): MoveRandomized[] {
    const move = getAutomaticMove(this.state)
    if (!move) return []
    if (move.type === MoveType.RollShowdownDice || move.type === MoveType.DrawFromBag) return [] // unpredictable output
    return [move]
  }
}
